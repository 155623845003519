<template>
  <form-history-layout noHeader :type="'event_sport'" :items="sportList">
    <template v-slot:formLayout>
      <div class="text-end my-2">
        ท่านมี {{ profile.coin_total }} เหรียญ
      </div>
      <div class="text-center text-h4">
        ทายผลบอล {{ sport.id || '' }}
      </div>
      <div class="text-center">
        รายการ: {{ sport.name || 'ไม่พบตารางกิจกรรม' }}
      </div>
      <div class="text-center">
        ประจำวันที่: {{ sport.start_at || '-' }}
      </div>
      <div class="d-flex my-5">
        <v-simple-table class="flex-grow-1">
          <template v-slot:default>
            <thead>
              <tr>
                <th>เจ้าบ้าน</th>
                <th>ผล</th>
                <th>ทีมเยือน</th>
                <th>
                  <span v-if="canBuy">ทายผล</span>
                  <span v-else>ผลการแข่งขัน</span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in sport.table" :key="index">
                <td>{{ item.team1 }}</td>
                <td>{{ item.score }}</td>
                <td>{{ item.team2 }}</td>
                <td>
                  <v-radio-group v-if="canBuy" v-model="guess[index]" row>
                    <v-radio value="win" label="เจ้าบ้านชนะ" />
                    <v-radio value="lose" label="ทีมเยือนชนะ" />
                    <v-radio value="draw" label="เสมอ" />
                  </v-radio-group>
                  <span v-else-if="item.result == 'cancel'">ยกเลิก</span>
                  <span v-else-if="item.result == 'win'">เจ้าบ้านชนะ</span>
                  <span v-else-if="item.result == 'lose'">ทีมเยือนชนะ</span>
                  <span v-else-if="item.result == 'draw'">เสมอ</span>
                  <span v-else>รอผลการแข่งขัน</span>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-simple-table v-if="sportGuess" class="ml-1">
          <template v-slot:default>
            <thead>
              <tr>
                <th>ทายผล</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in sportGuess.guess" :key="index">
                <td>
                  <span v-if="item == 'win'">เจ้าบ้านชนะ</span>
                  <span v-else-if="item == 'lose'">ทีมเยือนชนะ</span>
                  <span v-else-if="item == 'draw'">เสมอ</span>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>
      <div class="d-flex justify-end my-2">
        <v-btn v-if="canBuy" @click="submit" :loading="isLoading">ทายผล</v-btn>
      </div>
      <div class="text-subtitle">
        กติกาทายผลบอล
        <ol>
          <li>กติการทายผลบอล</li>
          <li>ต้องใช้เหรียญ {{ sport.price || '-' }} เหรียญในการทาย 1 วันสามารถทายได้ 1 ครั้ง</li>
          <li>ต้องทาย เจ้าบ้านชนะ, ทีมเยือนชนะ หรือ เสมอทุกคู่ที่มีการแสดง</li>
          <li>ท่านจะได้รับเงินรางวัลต่อเมื่อทายผลฟุตบอลถูกครบทุกคู่เท่านั้น โดยจะได้รับรางวัล {{ sport.reword }} บาท</li>
          <li>หากมีคู่บอลยกเลิกไป ทางทีมงานจะนำเงินมาหาร 2 หากท่านได้รับรางวัล</li>
          <li>ak55bet ขอสงวนสิทธิ์ในการยกเลิกโปรโมชั่นนี้ ไม่ว่ากรณีใดหรือเวลาใดก็ตาม โดยจะไม่แจ้งให้ท่านทราบล่วงหน้า</li>
        </ol>
      </div>
    </template>

    <template v-slot:table>
      <thead>
        <tr>
          <th>#</th>
          <th>ทายผล</th>
          <th>ผล</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in sportList" :key="index">
          <td>{{ item.sport_id }}</td>
          <td>{{ item.created_at }}</td>
          <td>{{ item.comment }} {{ item.description ? `#${item.description}"` : '' }}</td>
        </tr>
      </tbody>
    </template>
  </form-history-layout>
</template>
<script>
import FormHistoryLayout from '../../layouts/sub_layouts/FormHistoryLayout.vue'
import { mapGetters, mapActions } from 'vuex'
import moment from 'moment'
export default {
  components: {
    FormHistoryLayout,
  },
  data() {
    return {
      isLoading: false,
      list: [],
      guess: []
    }
  },
  methods: {
    ...mapActions('sport', ['buySport']),
    async submit() {
      if (this.guess.length != this.sport.table.length) {
        this.$store.dispath('app/showError', { error: { message: 'กรุณาทายผล' } }, { root: true })
        return
      }
      if (this.guess.filter(g => !g).length) {
        this.$store.dispath('app/showError', { error: { message: 'กรุณาทายผล ให้ครบทุกคู่' } }, { root: true })
        return
      }
      this.isLoading = true
      let data = {
        guess: this.guess,
        id: this.sport.id
      }
      await this.buySport(data)
      this.guess = []
      this.isLoading = false
    }
  },
  computed: {
    ...mapGetters('app', ['sport']),
    ...mapGetters('sport', ['sports', 'sportQueues']),
    ...mapGetters('member', ['profile']),
    canBuy() {
      if (this.sports && Object.values(this.sports).filter(s => s.sport_id == this.sport.id).length) {
        console.log('same sports')
        return false
      }
      if (this.sportGuess && Object.values(this.sportQueues).filter(s => s.sport_id == this.sport.id).length) {
        console.log('same sportQueues')
        return false
      }

      if (moment().diff(this.sport.exp_at, 'minutes') >= 0) {
        console.log('expired')
        return false
      }
      return true
    },
    sportGuess() {
      const queue = Object.values(this.sportQueues).filter(s => s.sport_id == this.sport.id)
      if (queue.length) {
        return queue[0]
      }
      const sport = Object.values(this.sports).filter(s => s.sport_id == this.sport.id)
      if (sport.length) {
        return sport[0]
      }
      return null
    },
    sportList() {
      const list = []
      list.push(...Object.values(this.sportQueues))
      list.push(...Object.values(this.sports).filter(s => s))
      return list
    }
  },

}
</script>
