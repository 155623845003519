<template>
  <v-container class="formHistoryPage" fluid>
    <v-row justify="center" no-gutters>
      <v-col cols="12">
        <div v-if="!noHeader" style="background: linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(1,51,109,1) 20%, rgba(1,42,90,1) 80%, rgba(0,0,0,1) 100%); font-size: 1.3em" class="text-center py-2 mb-2">
          <v-row no-gutters>
            <v-col cols="4" sm="2" class="text-left">
              <v-btn color="#fff" outlined style="border: 1px solid #fff;" class="ml-3" to="dashboard">
                <v-icon>mdi-arrow-left-circle-outline</v-icon>
                {{ $t('common.back') }}
              </v-btn>
            </v-col>
            <v-col cols="8" sm="8" class="text-right text-sm-center white--text">
              {{ $t('message.casino_text') }}
            </v-col>
            <v-col class="d-none d-sm-block"  cols="2"></v-col>
          </v-row>
        </div>
        <div v-if="!noHeader" class="titleBar white--text">
          <h1 class="text-center py-1">
            <img :src="`/images/icon/${type}.png`" height="25px" alt="" class="mr-2" style="vertical-align: middle;">
            {{ $t(`${type}.text`) }}
          </h1>
        </div>
        <div class="white--text">
          <v-row class="justify-center">
            <v-col cols="12" md="11" lg="10">
              <div style="background: linear-gradient(to bottom,#0F1427, rgba(15, 20, 39, 0.75));" class="px-6">
                <v-row class="justify-center">
                  <v-col cols="12" md="8">
                    <v-row>
                      <v-col cols="12">
                        <div style="border: 1px solid #505051; border-radius: 10px;" class="py-6 px-6">
                          <div class="formLayout">
                            <slot name="formLayout" />
                          </div>
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" md="4">
                    <div>
                      <img src="/images/bg4.png" width="100%" alt="">
                    </div>
                    <div>
                      <img src="/images/bg5.png" width="100%" alt="">
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <div style="background: linear-gradient(0deg, #141b34 0%, #212c54 100%);" class="text-center">
                      <h1 style="font-size: 1.2em; font-weight: 400;" class="mb-0 py-1">{{ $t(`${type}.history`) }}</h1>
                    </div>
                    <slot name="append-table"></slot>
                    <v-simple-table>
                      <template v-slot:default>
                        <slot name="table">
                          <thead back>
                            <tr>
                              <th class="text-left">{{ $t(`${type}.id`) }}</th>
                              <th class="text-left">{{ $t(`${type}.date`) }}</th>
                              <th>{{ $t(`${type}.amount`) }}</th>
                              <th>{{ $t(`${type}.comment`) }}</th>
                              <th>{{ $t(`${type}.status`) }}</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(item, index) in items" :key="(item.id || Math.random()) +''+ new Date()"
                            >
                              <td><slot name="item.id" :item="item" :index="index"> {{ item.id }} </slot></td>
                              <td><slot name="item.date" :item="item" :index="index"> {{ date_format(item.created_at) }}</slot></td>
                              <td align="right"><slot name="item.amount" :item="item" :index="index"> {{ (parseFloat(item.amount) || 0).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') }} </slot></td>
                              <td><slot name="item.comment" :item="item" :index="index"> {{ item.comment || '-' }} | {{ item.description || ''}} </slot></td>
                              <td align="center"><slot name="item.status" :item="item" :index="index"> {{ item.status }} </slot></td>
                            </tr>
                          </tbody>
                        </slot>
                      </template>
                    </v-simple-table>
                  </v-col>
                </v-row>
              </div>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
    <slot name="outter"></slot>
  </v-container>
</template>

<script>
export default {
  props: {
      noHeader: {
        type: Boolean,
        default: false
      },
      type: {
        type: String,
        default: 'deposit'
      },
      items: {
        type: Array,
        default: () => []
      },
      headers: {
        type: Array,
        default: () => [
          {text: 'id', value: 'id', class: 'text-left'},
          {text: 'date', value: 'created_at', class: 'text-left'},
          {text: 'amount', value: 'amount', class: ''},
          {text: 'comment', value: 'comment', class: ''},
          {text: 'status', value: 'status', class: ''}
        ]
      }
  },
  data() {
    return {
    }
  },
  created() {},
  mounted() {},
  methods: {
    date_format(dateText) {
      const d = new Date(dateText).toLocaleString('en-GB',{
        timeZone: 'Asia/Bangkok',
        year: 'numeric',
        month: '2-digit',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        timeZoneName: 'short'
      }).replace(',', '').split(' GMT')[0]
      return d + ' น.'
    }

  },
  computed: {}
}
</script>

<style lang="scss" scoped>
.formHistoryPage::v-deep {
  .titleBar { background: linear-gradient(0deg, #141b34 0%, #212c54 100%); padding: 4px 0; margin-bottom: 10px;
    h1 { font-size: 1.3em; font-weight: 400; margin-bottom: 0; }
  }
  .step { border-radius: 50%; background: #3ab54a; color: #fff; text-align: center; height: 30px; width: 30px; vertical-align: middle; padding: 6px; }
  .content {
    h3 { font-size: 1.4em; font-weight: 400; }
    h6 { font-size: 1em; font-weight: 400; margin-bottom: 15px; }
    p { color: #308ae7; margin-bottom: 5px; }
  }
  .formLayout {
    .v-input__append-outer { margin-top: 0!important; }
    .textInputDark {
      input { color: #000; }
    }
  }
  .btnSubmit { background: linear-gradient(#0969c5, #000a2d); border-radius: 5px; border: 1px solid #bcbcbc; color: #fff; padding: 10px 25px; display: inline-block; margin-top: 20px; }
}
@media (max-width: 959px) {
  .accountPage {
    .accountLayout {
      a { width: 100%; }
    }
  }
}
</style>
